import React from "react"
import MainLayout from "../components/MainLayout"
import PageHeading from "../components/PageHeadings"
import Application from "../components/Application"
import Image from "../images/hero.png"
import styled from "styled-components"
import ProductPageComponent from "../components/ProductPageComponent"

import { Helmet } from "react-helmet"
import SEO from "../components/seo"

import DiskImageMain1 from "../images/KODIAK-k11-17-D-front-WEB-Large.jpg"

import DiskImage1 from "../images/k12-white-bg-disk-thumbnail.jpg"
import DiskImage2 from "../images/KODIAK-k11-17-D-front-side-WEB-THUMB.jpg"
import DiskImage3 from "../images/KODIAK-K11-17-D-front-side-back-WEB-THUMB.jpg"
import DiskImage4 from "../images/KODIAK-K11-17-D-front-side-back-twist-WEB-THUMB.jpg"

import CylindricalImage1 from "../images/k12-white-bg-cylindrical-thumbnail.jpg"
import CylindricalImage2 from "../images/KODIAK-K11-24-C-front-side-WEB-THUMB.jpg"

import OrbitalImage1 from "../images/k12-white-bg-orbital-thumbnail.jpg"
import OrbitalImage2 from "../images/KODIAK-K11-20-0-front-side-WEB-THUMB.jpg"
import OrbitalImage3 from "../images/KODIAK-K11-20-0-front-side-back-WEB-THUMB.jpg"
import OrbitalImage4 from "../images/KODIAK-K11-20-0-front-side-back-twist-WEB-THUMB.jpg"

import DiskImage1Large from "../images/k12-white-bg-disk.jpg"
import DiskImage2Large from "../images/KODIAK-k11-17-D-front-side-WEB-Large.jpg"
import DiskImage3Large from "../images/KODIAK-K11-17-D-front-side-back-WEB-Large.jpg"
import DiskImage4Large from "../images/KODIAK-K11-17-D-front-side-back-twist-WEB-Large.jpg"

import CylindricalImage1Large from "../images/k12-white-bg-cylindrical.jpg"
import CylindricalImage2Large from "../images/KODIAK-K11-24-C-front-side-WEB-Large.jpg"

import OrbitalImage1Large from "../images/k12-white-bg-orbital.jpg"
import OrbitalImage2Large from "../images/KODIAK-K11-20-0-front-side-WEB-Large.jpg"
import OrbitalImage3Large from "../images/KODIAK-K11-20-0-front-side-back-WEB-Large.jpg"
import OrbitalImage4Large from "../images/KODIAK-K11-20-0-front-side-back-twist-WEB-Large.jpg"

import CylindricalImageThumb from "../images/cylindrical-3d-small.jpg"
import DiskImageThumb from "../images/disk-thumb.jpg"
import OrbitalImageThumb from "../images/orbital-thumb.jpg"
import OrbitalImage from "../images/orbital-deck-400.png"
import CylindricalImage from "../images/cylindrica-deck-imagel-400x.png"
import DiskImage from "../images/disk-deck-400.png"

const K11PageMain = styled.main`
  padding: 2rem;
`

const K11SeriesPage = props => {
  return (
    <>
      <SEO title="Floor Scrubbers: K12 Walk-Behind Industrial Cleaning Equipment - Kodiak Cleaning Equipment" />
      <Helmet>
        <title>
          Floor Scrubbers: K12 Walk-Behind Industrial Cleaning Equipment -
          Kodiak Cleaning Equipment
        </title>
        <description></description>
      </Helmet>
      <MainLayout>
        <PageHeading
          pageHeading="K12 Floor Scrubbers"

          // description="Here is the info about the K3 scrubbers and what they are used for"
        />
        {/* <h1 style={{ color: "red", textAlign: "center" }}>
          **Coming Fall 2020**
        </h1> */}
        <K11PageMain>
          <ProductPageComponent
            chooseTitle="Choose Your Scrub Deck"
            descriptionTitle1="Solution Tank Capacity:"
            descriptionTitle2="Run Time:"
            mainDiskImage={DiskImage1Large}
            mainCylindricalImage={CylindricalImage1Large}
            mainOrbitalImage={OrbitalImage1Large}
            thumbDiskImage1={DiskImage1}
            thumbDiskImage2={DiskImage2}
            thumbDiskImage3={DiskImage3}
            thumbDiskImage4={DiskImage4}
            thumbCylindricalImage1={CylindricalImage1}
            thumbCylindricalImage2={CylindricalImage2}
            thumbOrbitalImage1={OrbitalImage1}
            thumbOrbitalImage2={OrbitalImage2}
            thumbOrbitalImage3={OrbitalImage3}
            thumbOrbitalImage4={OrbitalImage4}
            DiskImage1Large={DiskImage1Large}
            DiskImage2Large={DiskImage2Large}
            DiskImage3Large={DiskImage3Large}
            DiskImage4Large={DiskImage4Large}
            CylindricalImage1Large={CylindricalImage1Large}
            CylindricalImage2Large={CylindricalImage2Large}
            OrbitalImage1Large={OrbitalImage1Large}
            OrbitalImage2Large={OrbitalImage2Large}
            OrbitalImage3Large={OrbitalImage3Large}
            OrbitalImage4Large={OrbitalImage4Large}
            diskTitle="K12 Disk"
            subTitle="Walk-Behind Small Floor Scrubber"
            diskDescription="Cleaning small areas with a budget in mind has just gotten easier with the K12 Series of Floor Scrubbers.  Don’t let the size fool you, this floor scrubber provides premium performance and durability compared to others in the market.  Maintain cleanliness in Auto Shops, Sports Arenas, and Small Warehouses easily.  The squeegee blades and brushes can be rotated or flipped without tools to allow maximum usage prior to replacement and to aid in easy changes."
            diskCleaningPath="17 - 20in (43 - 51cm)"
            diskGallons="Sol: 12gal (45.4L) Rec: 15gal (56.8L)"
            diskRunTime=" Up to 2.5 hours"
            cylindricalTitle="K12 Cylindrical"
            cylindricalDescription="Cleaning small areas with a budget in mind has just gotten easier with the K12 Series of Floor Scrubbers.  Don’t let the size fool you, this floor scrubber provides premium performance and durability compared to others in the market.  Maintain cleanliness in Auto Shops, Sports Arenas, and Small Warehouses easily.  The squeegee blades and brushes can be rotated or flipped without tools to allow maximum usage prior to replacement and to aid in easy changes."
            cylindricalCleaningPath="17 - 20in (43 - 51cm)"
            cylindricalGallons="Sol: 12gal (45.4L) Rec: 15gal (56.8L)"
            cylindricalRunTime=" Up to 2.5 hours"
            orbitalTitle="K12 Orbital"
            orbitalDescription="Cleaning small areas with a budget in mind has just gotten easier with the K12 Series of Floor Scrubbers.  Don’t let the size fool you, this floor scrubber provides premium performance and durability compared to others in the market.  Maintain cleanliness in Auto Shops, Sports Arenas, and Small Warehouses easily.  The squeegee blades and brushes can be rotated or flipped without tools to allow maximum usage prior to replacement and to aid in easy changes."
            orbitalCleaningPath="17 - 20in (43 - 51cm)"
            orbitalGallons="Sol: 12gal (45.4L) Rec: 15gal (56.8L)"
            orbitalRunTime=" Up to 2.5 hours"
            DeckImageThumb1={DiskImageThumb}
            DeckImageThumb2={CylindricalImageThumb}
            DeckImageThumb3={OrbitalImageThumb}
            DeckImage1Description="Disk"
            DeckImage2Description="Cylindrical"
            DeckImage3Description="Orbital"
            DeckImageLarge1={DiskImage}
            DeckImageLarge2={CylindricalImage}
            DeckImageLarge3={OrbitalImage}
            // deckTitle="Why Disk"
            deckDescription1="Wavy / Irregular Floors"
            deckDescription2="Lowest Maintenance Cost"
            deckDescription3="Allows Pads"
            deckTitle2="Why Cylindrical"
            deckDescription4="Scrub & Sweep Simultaneously"
            deckDescription5="Tile & Grout Cleaning"
            deckDescription6="Rubber Surfaces & Track Fields"
            // deckTitle="Why Orbital"
            deckDescription7="Chemical Free Stripping"
            deckDescription8="70% Reduction in Water Usage"
            deckDescription9="Floor Finish Prep & Recoat"
            brochurePdf="../kodiak-walk-behind-sm.pdf"
            techPdf="../kodiak-k12-specsheet.pdf"
            partsPdf="https://www.dropbox.com/s/x08bnughqhoole3/K-K12-P-EN.pdf?dl=0"
            operatorPdf="https://www.dropbox.com/s/emp4wx46gc1cx1l/K-K12-OP-EN.pdf?dl=0"
            hoodPdf="../kodiak-underthehood.pdf"
            mobileDiskImage={DiskImageThumb}
            mobileCylindricalImage={CylindricalImageThumb}
            mobileOrbitalImage={OrbitalImageThumb}
            mobileDiskTitle="Disk"
            mobileCylindricalTitle="Cylindrical"
            mobileOrbitalTitle="Orbital"
            PmPdf="../K12-Series-PM.pdf"
            mtLink="https://www.dropbox.com/s/evrcuco63ko0478/daily-weekly-maintenance-3rd-v21306.pdf?dl=0"
          />
        </K11PageMain>
      </MainLayout>
    </>
  )
}

export default K11SeriesPage
